import { render, staticRenderFns } from "./fila.vue?vue&type=template&id=c5d748ac&scoped=true&"
import script from "./fila.vue?vue&type=script&lang=js&"
export * from "./fila.vue?vue&type=script&lang=js&"
import style0 from "./fila.vue?vue&type=style&index=0&id=c5d748ac&scoped=true&lang=css&"
import style1 from "./fila.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5d748ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VDataTable,VDialog,VIcon})
