<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Prontuários</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="pacienteInfo && unidade.id != 24"
          class="btn-anamnese"
          @click="dialogAddAnaminese = true"
          small
        >
          <strong>Anamnese Adicional</strong>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-12 text-end my-1">
              <v-dialog v-model="dialogAddAnaminese" fullscreen>
                <v-card>
                  <v-toolbar style="background-color: #1daf80; color: #fff">
                    <v-btn icon dark @click="dialogAddAnaminese = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span class="headline">Anamnese Adicional</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items> </v-toolbar-items>
                  </v-toolbar>
                  <modal-nova-anaminese
                    v-if="dialogAddAnaminese"
                    :paciente="paciente"
                    @close="(dialogAddAnaminese = false), listProntuarios()"
                  />
                </v-card>
              </v-dialog>

              <!-- <v-dialog fullscreen v-model="dialogAddAnaminese">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="pacienteInfo && unidade.id != 24"
                    class="btn-style-green"
                    color="#3E682A"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>Anamnese Adicional</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar style="background-color: #1daf80; color: #fff">
                    <v-btn icon dark @click="dialogAddAnaminese = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span class="headline">Anamnese Adicional</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items> </v-toolbar-items>
                  </v-toolbar>
                  <modal-nova-anaminese
                    v-if="dialogAddAnaminese"
                    :paciente="paciente"
                    @close="(dialogAddAnaminese = false), listProntuarios()"
                  />
                </v-card>
              </v-dialog> -->
            </div>
          </div>
          <div v-if="!loadingConsultas">
            <div>
              <v-slide-group v-model="dataSelecMenu" center-active show-arrows>
                <v-slide-item
                  v-for="dataItem in datasFiltros"
                  :key="dataItem"
                  v-slot="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'card-ativo' : 'card-inativo'"
                    class="ma-4 d-flex justify-content-center align-items-center"
                    height="80"
                    width="80"
                    @click="clearToggle(toggle, active)"
                  >
                    <span class="m-0">
                      {{ dataItem.desc }}
                    </span>
                    <!-- <v-row class="fill-height" align="center" justify="center">
                    <v-scale-transition>
                      <v-icon
                        v-if="active"
                        color="white"
                        size="48"
                        v-text="'mdi-close-circle-outline'"
                      ></v-icon>
                    </v-scale-transition>
                  </v-row> -->
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </div>

            <v-expansion-panels v-model="panel">
              <v-expansion-panel
                @click="initConsulta(item, i)"
                class="m-2"
                v-for="(item, i) in consultasWithEdited"
                :key="i"
              >
                <span class="border d-flex justify-content-between p-2 px-5">
                  <b>
                    Executor: {{ item.nome_assistente || item.nome_medico }}</b
                  >
                  <div>
                    <b class="text-white orange px-3" v-if="item.req_st == 5">
                      Anamnese Adicional
                    </b>
                    <!-- <v-btn text rounded small @click="gerarPdf(item, i)"> -->
                    <v-btn text rounded small @click="getInfosPdf(item, i)">
                      <v-icon>mdi-printer-outline</v-icon>
                    </v-btn>
                  </div>
                </span>
                <v-expansion-panel-header v-slot="{ open }">
                  <v-row no-gutters>
                    <v-col cols="4" class="align-self-center">
                      Data: {{ item.data | data }} <br />
                    </v-col>
                    <v-col cols="8" class="text--secondary">
                      <v-fade-transition leave-absolute>
                        <span v-if="open" key="0" class="d-flex">
                          <v-col cols="10" class="align-self-center">
                            Procedimento:{{ item.procedimento }}
                          </v-col>
                        </span>
                        <span v-else key="1" class="d-flex">
                          <v-col cols="7" class="align-self-center">
                            {{ item.procedimento }}
                          </v-col>
                          <v-col cols="5">
                            Há {{ calcular_tempo(item) }}
                          </v-col>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template v-if="consulta.auditoria">
                    <h4>Processo realizado pela auditoria</h4>
                  </template>
                  <template v-else-if="consulta.formulario_id">
                    <v-card elevation="0">
                      <v-tabs color="green darken-4">
                        <v-tab>
                          <h5>Resumo</h5>
                        </v-tab>
                        <!-- <v-tab v-if="classificarFotos3 > 0">
                        <h5>Classificar fotos</h5>
                      </v-tab>
                      <v-tab v-if="Fotosclassificadas1 > 0">
                        <h5>Antes e depois</h5>
                      </v-tab> -->

                        <!-- RESUMO -->
                        <v-tab-item>
                          <v-card flat :loading="loading">
                            <v-card-text>
                              <v-row
                                no-gutters
                                class="text-left flex-column"
                                v-if="!loading"
                              >
                                <v-row class="justify-end">
                                  <div>
                                    <v-btn
                                      color="green darken-3"
                                      class="mx-2"
                                      rounded
                                      small
                                      @click="imprimirAnamnese(item)"
                                    >
                                      <v-icon color="#ffffff">
                                        mdi mdi-eye
                                      </v-icon>
                                    </v-btn>
                                  </div>
                                  <v-btn
                                    color="green darken-3"
                                    small
                                    v-if="checkEdited(item)"
                                    @click="item.editedItem = !item.editedItem"
                                  >
                                    <v-icon
                                      v-if="item.editedItem"
                                      color="#ffffff"
                                    >
                                      mdi-pencil
                                    </v-icon>
                                    <v-icon v-else color="#ffffff">
                                      mdi-close
                                    </v-icon>
                                  </v-btn>
                                </v-row>
                                <v-row class="flex-column">
                                  <label for="resumo"
                                    ><strong>Resumo: </strong>
                                  </label>
                                  <v-text-field
                                    :disabled="item.editedItem"
                                    id="resumo"
                                    v-model="consulta.consulta_descricao"
                                  ></v-text-field>
                                </v-row>
                                <v-row>
                                  <div
                                    v-for="(pergunta,
                                    index) in consulta.perguntas"
                                    :key="index"
                                    :class="
                                      pergunta.id_tipo_pergunta === 2
                                        ? 'paragrafo'
                                        : ''
                                    "
                                  >
                                    <consulta-fill
                                      @rchange="atributeModel"
                                      :pergunta="pergunta"
                                      :disabled="!item.editedItem"
                                    />
                                  </div>
                                </v-row>
                                <v-row class="flex-column">
                                  <v-col>
                                    <v-btn
                                      color="green darken-3"
                                      @click="atualizarProntuario"
                                      v-if="!item.editedItem"
                                    >
                                      <v-icon color="#ffffff">
                                        mdi-pencil
                                      </v-icon>
                                      <span style="color: #ffffff"
                                        >Salvar alterações</span
                                      >
                                    </v-btn>
                                  </v-col>
                                </v-row>

                                <v-row class="flex-column">
                                  <h4 class="pl-3">Adendos</h4>
                                </v-row>

                                <v-row class="flex-column">
                                  <p
                                    class="adendo"
                                    :key="adendo.id"
                                    v-for="adendo in adendos"
                                  >
                                    (<b v-if="adendo.executor"
                                      >{{ adendo.executor }} -
                                    </b>
                                    <span
                                      >{{
                                        adendo.data
                                          .split("-")
                                          .reverse()
                                          .join("/")
                                      }}
                                      - {{ adendo.hora }}</span
                                    >) -
                                    {{ adendo.descricao }}
                                  </p>
                                </v-row>

                                <v-row class="flex-column">
                                  <v-col>
                                    <v-text-field
                                      v-model="adendo[i]"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col>
                                    <v-btn
                                      @click="addAdendo(i)"
                                      class="green darken-1 white--text"
                                      >Adicionar</v-btn
                                    >
                                  </v-col>
                                </v-row>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-card>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <v-progress-circular
            v-else
            :width="3"
            :size="20"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-container>
      </v-card-text>
      <v-dialog v-model="dialogImprimir" fullscreen>
        <v-card>
          <v-toolbar style="background-color: #3e682a; color: #fff">
            <v-btn icon dark @click="dialogImprimir = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <span class="headline"
                >Anamnese - {{ consultaImprimir.nome_pet }}</span
              >
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="p-5">
            <v-row class="">
              <label for="resumo"><strong>Resumo: </strong> </label>
              <v-text-field
                id="resumo"
                readonly
                v-model="consultaImprimir.consulta_descricao"
              ></v-text-field>
            </v-row>
            <v-row class="">
              <div
                v-for="(pergunta, index) in consulta.perguntas"
                :key="index"
                :class="pergunta.id_tipo_pergunta === 2 ? 'paragrafo' : ''"
              >
                <consulta-fill @rchange="atributeModel" :pergunta="pergunta" />
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
    <template>
      <div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          enable-download
          :paginate-elements-by-height="1400"
          :filename="`${anamnesePdf.procedimento} - ${anamnesePdf.data}`"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="html2Pdf"
        >
          <section
            slot="pdf-content"
            class="m-2 pdf-content"
            style="color: #000;"
          >
            <div
              class="d-flex justify-content-between align-items-center mx-auto"
              style="width: 95%;"
            >
              <img
                src="../../assets/images/logo-cvpc.png"
                alt="Logo Centro Veterinário Popular do Ceará"
                width="70px"
                height="70px"
              />
              <div class="w-50 cabecalho">
                <p><b>Emitente:</b> {{ anamnesePdf.executor }}</p>
                <v-template v-if="anamnesePdf.crmv">
                  <p><b>CRMV:</b>{{ anamnesePdf.crmv }}</p>
                </v-template>
                <v-template v-if="anamnesePdf.unidadeInfo">
                  <p>
                    <b>Unidade:</b>
                    {{ anamnesePdf.unidadeInfo.razao_social }}
                  </p>
                  <p>
                    <b>Endereço:</b> {{ anamnesePdf.unidadeInfo.logradouro }},
                    {{ anamnesePdf.unidadeInfo.logradouroNumero }}
                  </p>
                  <p>
                    {{ anamnesePdf.unidadeInfo.cidade }} -
                    {{ anamnesePdf.unidadeInfo.cep }}
                  </p>
                </v-template>
              </div>
            </div>
            <div class="mt-2">
              <div class="d-flex cabecalho">
                <p><b>Tutor:</b> {{ tutor.nome }}</p>
                <p><b>CPF:</b> {{ tutor.cpf }}</p>
                <p>
                  <b>Endereço:</b> {{ tutor.logradouro }}, {{ tutor.numero }} -
                  {{ tutor.cidade }}/{{ tutor.estado }} - {{ tutor.cep }}
                </p>
              </div>
              <div class="d-flex cabecalho">
                <p><b>Pet:</b> {{ pet.nome_pet }}</p>
                <!-- <p>Idade: 5 anos e 4 meses</p> -->
                <p><b>Espécie:</b> {{ pet.nome_especie }}</p>
                <p><b>Raça:</b> {{ pet.nome_raca }}</p>
                <p><b>Sexo:</b> {{ pet.sexo }}</p>
                <p><b>Peso:</b> {{ pet.peso }}</p>
              </div>
            </div>
            <hr style="color: #000;" />
            <div class="body mx-3">
              <div class="title ml-n3 mb-2">
                <p>
                  <b
                    v-if="anamnesePdf.tipo_anamnese == 'Anamnse Adicional'"
                    class="rounded px-1 mr-1"
                    style="background-color: orange; color: white;"
                  >
                    {{ anamnesePdf.tipo_anamnese }}
                  </b>
                  {{ anamnesePdf.data }} -
                  <b>{{ anamnesePdf.procedimento }}</b>
                  <!-- ({{
                    anamnesePdf.executor
                  }}) -->
                </p>
              </div>
              <div>
                <div v-for="pergunta in consulta.perguntas" :key="pergunta.id">
                  <b>{{ pergunta.texto_pergunta }}: </b>
                  <span v-if="[1, 2].includes(pergunta.id_tipo_pergunta)">
                    {{ pergunta.resposta_pergunta_textual }}
                  </span>
                  <span
                    v-else
                    v-for="resposta in pergunta.resposta_pergunta_opcao"
                    :key="resposta.id"
                  >
                    <span> - {{ resposta.descricao }} </span>
                  </span>
                </div>
                <b>Adendos: </b>
                <p v-for="adendo in adendos" :key="adendo.id">
                  ({{ adendo.executor }} -
                  {{
                    adendo.data
                      .split("-")
                      .reverse()
                      .join("/")
                  }}) {{ adendo.descricao }}
                </p>
              </div>
            </div>
          </section>
        </vue-html2pdf>
      </div>
    </template>
  </div>
</template>

<script>
// import FotosClassificadas from "@/components/prontuarios/fotos_classificadas.vue";
import ConsultaFill from "@/components/prontuarios/consulta_campo_fill.vue";
import ConsultaService from "@/services/consulta_service.js";
import AdendoService from "@/services/adendo_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import ModalNovaAnaminese from "@/components/recepcao_medico/infoChamada/nova_anaminese";
import PetsService from "@/services/pets_service.js";
import PacienteService from "../../services/paciente_service";
import MedicoService from "../../services/medico_service";
import UnidadeService from "../../services/unidade_service";
import VueHtml2pdf from "vue-html2pdf";

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin],
  components: {
    ConsultaFill,
    ModalNovaAnaminese,
    VueHtml2pdf,
    // FotosClassificadas,
  },

  props: {
    consultas: Array,
    pacienteInfo: Object,
  },
  async mounted() {
    // console.log(this.consultas);
    this.getTutor(this.pacienteInfo?.id_cliente);
    this.getPet(this.pacienteInfo?.id_pet);
    this.adendo = this.consultas.map(() => "");
    this.getConsultasFiltros();
    // this.consultasWithEdited = this.consultas.map((value) => {
    //   return {
    //     ...value,
    //     editedItem: true,
    //   };
    // });
    this.usuarioId = await JSON.parse(sessionStorage.vuex).usuario.id;
    this.paciente = {
      tutor_nome: this.pacienteInfo.nome,
      pet_nome: this.pacienteInfo.nome_pet,
      PetCodigo: this.pacienteInfo.id_pet,
      TutorCodigo: this.pacienteInfo.id_cliente,
    };
  },
  data: () => ({
    datasFiltros: [],
    dataSelecionada: {},
    dataSelecMenu: 0,
    adendo: [],
    consultaImprimir: {},
    dialogImprimir: false,
    adendos: [],
    consultasWithEdited: [],
    consulta: {},
    panel: [],
    // loadingClassificarFotos:true,
    // loadingFotos:true,
    loading: true,
    open: false,
    date: null,
    editar: true,
    trip: {
      name: "",
      location: null,
      start: null,
      end: null,
    },
    formularioEditar: {},
    plc: {},
    usuarioId: "",
    dialogAddAnaminese: false,
    paciente: "",
    petService: PetsService.build(),
    pacienteService: new PacienteService(),
    unidade: JSON.parse(sessionStorage.vuex).unidade,
    tutor: {},
    pet: {},
    anamnesePdf: {},
    service: {
      medicoService: MedicoService.build(),
      unidadeService: UnidadeService.build(),
    },
    meses: [
      { id: 1, desc: "Jan" },
      { id: 2, desc: "Fev" },
      { id: 3, desc: "Mar" },
      { id: 4, desc: "Abr" },
      { id: 5, desc: "Mai" },
      { id: 6, desc: "Jun" },
      { id: 7, desc: "Jul" },
      { id: 8, desc: "Ago" },
      { id: 9, desc: "Set" },
      { id: 10, desc: "Out" },
      { id: 11, desc: "Nov" },
      { id: 12, desc: "Dez" },
    ],
    loadingConsultas: true,
  }),
  watch: {
    panel(index) {
      if (index >= 0) {
        this.consulta = {};
        this.carregar_item(index);
      } else {
        this.consulta = {};
      }
    },
    dataSelecMenu(selected) {
      this.dataSelecionada = this.datasFiltros[selected];
      this.filtrarConsultas();
    },
  },
  methods: {
    async getInfosPdf(item, index) {
      let medico = null;
      let unidade = null;
      if (item.id_medico > 0 && item.id_medico) {
        await this.service.medicoService
          .getMedico(item.id_medico)
          .then(async (resp) => {
            let data = await resp.json();
            medico = data.data;
          });
      }
      await this.service.unidadeService
        .getUnidadeById(item.id_unidade)
        .then(async (resp) => {
          unidade = await resp.data.unidade[0];
        });
      this.gerarPdf(item, index, medico, unidade);
    },
    async gerarPdf(item, index, medico, unidade) {
      this.anamnesePdf = {
        ...item,
        crmv: medico != null ? medico.documento : "",
        unidadeInfo: {
          nome: unidade.nome_fantasia,
          razao_social: unidade.razao_social,
          logradouro: unidade.logradouro,
          logradouroNumero: unidade.numero,
          cidade: unidade.cidade,
          cep: unidade.cep,
        },
        data: item.data
          .split("-")
          .reverse()
          .join("/"),
        executor: item.nome_assistente || item.nome_medico,
      };
      await this.listAdendos(index);
      this.carregar_item(index, () => this.$refs.html2Pdf.generatePdf());

      // setTimeout(() => {
      // }, 3000);
    },
    getTutor(cliente_id) {
      this.pacienteService.getPacienteByClienteId(cliente_id).then((resp) => {
        this.tutor = resp.data;
      });
    },
    getPet(id) {
      this.petService.getPetById(id).then((resp) => {
        this.pet = {
          ...resp,
          sexo: resp.sexo == "M" ? "Macho" : "Fêmea",
          peso: resp.peso || "Não cadastrado",
        };
      });
    },
    initConsulta(item, index) {
      this.formularioEditar = item;
      this.listAdendos(index);
    },

    imprimirAnamnese(consulta) {
      console.log(consulta);
      this.dialogImprimir = true;
      this.consultaImprimir = consulta;
    },

    checkEdited() {
      if (
        this.$_GETTER_perfil.descricao.toLowerCase() == "medico" &&
        this.consulta.usuario_medico_id == this.$_GETTERS_usuario.id
      ) {
        return true;
      }

      if (
        this.$_GETTER_perfil.descricao.toLowerCase() == "assistente" &&
        this.consulta.usuario_executor_exame == this.$_GETTERS_usuario.id
      ) {
        return true;
      }
      return false;
    },
    isBeforeOrToday(data) {
      const data_parts = data.split("-");
      const data_consulta = new Date(
        data_parts[0],
        data_parts[1],
        data_parts[2]
      );
      console.log({
        data_consulta,
        data_parts,
        a: data_consulta <= new Date(),
      });
      return data_consulta <= new Date();
    },

    atributeModel(change) {
      const newValue = change.alteracao;
      const perguntaId = change.pergunta.id;

      const pergunta = this.consulta.perguntas.find((e) => e.id === perguntaId);
      const respostaCampos = {
        1: "resposta_pergunta_textual",
        2: "resposta_pergunta_textual",
        3: "resposta_pergunta_opcao",
        4: "resposta_pergunta_opcao",
      };
      const respostaCampo = respostaCampos[pergunta.id_tipo_pergunta];
      pergunta[respostaCampo] = newValue;
    },
    atualizarProntuario() {
      let formulario = this.formularioEditar;

      formulario.id_consulta = Number.parseInt(
        this.formularioEditar.id_consulta
      );
      formulario.titulo = this.formularioEditar.consulta_titulo;
      formulario.descricao = this.consulta.consulta_descricao;
      formulario.id_formulario = this.consulta.formulario_id;

      formulario.respostas = this.consulta.perguntas.map((resposta) => {
        if (resposta.resposta_pergunta_textual) {
          resposta.resposta = resposta.resposta_pergunta_textual;
        }

        if (resposta.resposta_pergunta_decimal) {
          resposta.resposta = resposta.resposta_pergunta_decimal;
        }

        if (resposta.resposta_pergunta_inteira) {
          resposta.resposta = resposta.resposta_pergunta_inteira;
        }

        if (resposta.resposta_pergunta_opcao) {
          resposta.resposta = resposta.resposta_pergunta_opcao;
        }

        resposta.id_pergunta = resposta.id;

        return resposta;
      });

      this.plc = this.consulta;

      const consultaService = new ConsultaService();
      consultaService.update(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.form_resp = {};
              this.resp = [];
              this.$_ACTIONS_showSnackbarMessage({
                message: "Alterações salvas com sucesso",
                color: "sucess",
              });
              // this.$emit("finalizarConsulta", { msg:body.message, cor:"sucess", nome: this.paciente.nome} );
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loadingNovoFormulario = false;
          },
        },
        formulario,
        formulario.id_consulta
      );
      this.formularioEditar.editedItem = true;
    },

    async listAdendos(index) {
      const adendoService = new AdendoService();
      const consulta_id = this.consultas[index].id_consulta;

      adendoService.getList(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.adendos = body.data.adendos;
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
            this.adendo[index] = "";
          },
        },
        consulta_id
      );
    },

    addAdendo(index) {
      const adendoService = new AdendoService();

      const adendoVM = {
        consulta_id: this.consultas[index].id_consulta,
        descricao: this.adendo[index],
        usuario_id: this.usuarioId,
      };

      adendoService.criarAdendo(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });

              this.listAdendos(index);
            }
          },
          onError: (error) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: error.message,
              color: "error",
            });
          },
          onEnd: () => {
            this.adendo[index] = "";
          },
        },
        adendoVM
      );
    },

    calcular_tempo(i) {
      var data = new Date(i.data);
      var hj = new Date();
      var dif = hj.getTime() - data.getTime();
      var dias = dif / (1000 * 3600 * 24);
      dias = Math.floor(dias);
      var resp = "";
      if (dias > 8) {
        var semanas = Math.floor(dias / 7);
        dias -= semanas * 7;
        resp = `${semanas} sem. e ${dias} dia(s) `;
        if (semanas > 4) {
          var meses = Math.floor(semanas / 4);
          semanas -= meses * 4;
          resp = `${meses} m., ${semanas} sem. e ${dias} dia(s) `;
          if (meses > 12) {
            var anos = Math.floor(meses / 12);
            meses -= anos * 12;
            resp = `${meses} ano(s) , ${meses} m., ${semanas} sem. e ${dias} dia(s) `;
          }
        }
      } else {
        resp = dias + " dia(s)";
      }
      return resp;
    },
    async carregar_item(index, callback) {
      const consultaService = new ConsultaService();
      consultaService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.consulta = body.data;
              this.consulta.perguntas = this.consulta.perguntas
                .map((pergunta) => {
                  if ([1, 2].includes(pergunta.id_tipo_pergunta)) {
                    return pergunta;
                  } else {
                    return {
                      ...pergunta,
                      resposta_pergunta_opcao: pergunta.opcoes.filter((op) => {
                        return pergunta.resposta_pergunta_opcao
                          .map((resp) => resp.resposta_opcao)
                          .includes(`${op.id}`);
                      }),
                    };
                  }
                })
                .sort((a, b) => {
                  if (a.pergunta_ordem < b.pergunta_ordem) return -1;
                  if (a.pergunta_ordem > b.pergunta_ordem) return 1;
                  return 0;
                });
              console.log(this.consulta.perguntas);
              if (callback) {
                callback();
              }
            } else if (status == 206) {
              this.consulta = { auditoria: true };
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
            console.log(this.consulta);
          },
        },
        this.consultas[index].id_consulta
      );

      //  setTimeout(() =>  this.loading = false, 1000);
      //     this.consulta = this.consultas[index]
    },
    sem_fotos_para_classificar() {
      this.loadingClassificarFotos = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: "Não coseguimos encontrar as fotos",
        color: "error",
      });
    },
    sem_fotos() {
      this.loadingFotos = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: "Não coseguimos encontrar as fotos",
        color: "error",
      });
    },
    async listProntuarios() {
      await this.petService
        .getConsultasbyUnidade(
          this.paciente.PetCodigo,
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        // .getConsultas(this.paciente.PetCodigo)
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: data.message,
              color: response.status === 200 ? "sucess" : "error",
            });
          }
          // console.log(data);
          this.consultas = data.data.consultas;
          this.getConsultasFiltros();
          // this.consultasWithEdited = this.consultas.map((value) => {
          //   return {
          //     ...value,
          //     editedItem: true,
          //   };
          // });
        })
        .finally(() => {});
    },
    getDatasConsultas() {
      let datas = [];
      for (let index = 0; index < this.consultas.length; index++) {
        const element = this.consultas[index];
        let indexData = datas.findIndex((eleFilter) => {
          return eleFilter.value == element.data;
        });
        if (indexData == -1) {
          datas.push(this.tratarDatas(element.data));
        }
      }
      datas.sort(function(a, b) {
        if (a.value > b.value) {
          return -1;
        }
        if (a.value < b.value) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
      return datas;
    },
    tratarDatas(item) {
      let itens = item.split("-");
      let mes = this.meses.find((element) => element.id == itens[1]);
      return { value: item, desc: `${itens[2]} ${mes.desc} ${itens[0]}` };
    },
    filtrarConsultas() {
      this.consultasWithEdited = [];
      console.log("this.consultas");
      console.log(this.consultas);
      console.log("this.dataSelecionada");
      console.log(this.dataSelecionada);
      for (let index = 0; index < this.consultas.length; index++) {
        const element = this.consultas[index];
        if (element.data == this.dataSelecionada.value) {
          this.consultasWithEdited.push({
            ...element,
            editedItem: true,
          });
        }
      }
    },
    clearToggle(toggle, active) {
      if (!active) {
        toggle();
        this.panel = [];
      }
    },
    async getConsultasFiltros() {
      this.loadingConsultas = true;
      this.datasFiltros = [];
      this.datasFiltros = await this.getDatasConsultas();
      this.dataSelecMenu = 0;
      this.dataSelecionada = this.datasFiltros[0];
      this.filtrarConsultas();
      this.loadingConsultas = false;
    },
  },
};
</script>

<style scoped>
.pdf-content .body p {
  font-size: 14px;
}
.cabecalho p {
  font-size: 12px;
}
p {
  margin: 0px 10px !important;
  padding: 0px 10px !important;
  font-size: 16px;
}
.paragrafo {
  width: 770px !important;
}
label {
  font-weight: 900;
  font-size: 1.2rem;
}
.v-application {
  line-height: 0.01;
}
.btn-style-green {
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
.card-ativo {
  background-color: #1daf80;
}
.card-ativo span,
.card-inativo span {
  font-weight: bold;
  color: white;
}
.card-inativo {
  background-color: #7e7e7e;
}
.btn-anamnese {
  color: #1daf80;
}
</style>
