<template>
  <div class="row">
    <v-col class="row justify-content-center">
      <v-col class="col-3">
        <!-- <v-select
          v-model="dashboard"
          :items="['legado', 'nova']"
          label="Dashboard"
        ></v-select> -->
        <v-select
          v-model="statusComp"
          :items="[
            { desc: 'Todos', id: 0 },
            { desc: 'Atendidos', id: 1 },
            { desc: 'Pendente', id: 2 },
          ]"
          item-text="desc"
          item-value="id"
          label="Status"
        ></v-select
      ></v-col>
      <div class="col-4">
        <v-text-field
          v-if="dashboard == 'nova'"
          v-model="data_consultas"
          label="Data"
          type="date"
        ></v-text-field>
      </div>
      <div class="col-2">
        <v-btn
          @click="atualizarCompromissos"
          class="mt-4 btn-style-green"
          elevation="2"
          >Atualizar</v-btn
        >
      </div>
    </v-col>
    <div v-if="dashboard == 'legado'">
      <fila-atual
        v-if="compromissos.length"
        :consultaId="consultaId"
        :compromissos="compromissos"
        @iniciar-consulta="iniciarConsulta($event)"
        @paciente-ausente="ativarModalAtrasoPaciente"
        @paciente="paciente = $event"
      />

      <clientes-atrasados
        :compromissos="compromissos"
        @iniciar-consulta="iniciarConsulta"
        @remover="removerPacienteDaListaDeAtrasados($event)"
      />

      <compromissos :compromissos="compromissos" />

      <v-dialog
        v-model="modalConsulta"
        fullscreen
        scrollable
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <modal-iniciar-consulta
          :paciente="paciente"
          :info="info"
          @close="cancelarConsulta"
          @finalizarConsulta="finalizarConsulta($event)"
        />
      </v-dialog>
      <dialog-confirmacao-atraso
        :enabled="modalAtrasoPaciente"
        @cancel="modalAtrasoPaciente = false"
        @confirm="moverPacienteParaListaDeAtrasados"
      />
    </div>
    <div v-else class="tabela_agenda_lista" style="width: 100vw">
      <div v-if="!loading">
        <fila-tabela
          v-if="compromissos.length > 0"
          :compromissos="compromissos"
          :consulta-id="consultaId"
          @iniciar-consulta="iniciarConsulta($event)"
          @paciente-ausente="ativarModalAtrasoPaciente"
          @paciente="paciente = $event"
          @atualizarCompromissos="atualizarCompromissos"
        />
        <div v-if="compromissos && compromissos.length < 1">
          <h3 class="text-center">Sem Registros</h3>
        </div>
      </div>
      <v-progress-circular
        indeterminate
        color="green"
        v-else
      ></v-progress-circular>

      <v-dialog
        v-model="modalConsulta"
        fullscreen
        scrollable
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <modal-iniciar-consulta
          :paciente="paciente"
          :info="info"
          @close="cancelarConsulta"
          @finalizarConsulta="finalizarConsulta($event)"
        />
      </v-dialog>
      <dialog-confirmacao-atraso
        :enabled="modalAtrasoPaciente"
        @cancel="modalAtrasoPaciente = false"
        @confirm="moverPacienteParaListaDeAtrasados"
      />
    </div>
  </div>
</template>

<script>
import FilaAtual from "./fila_atual";
import FilaTabela from "./fila_tabela";
import ClientesAtrasados from "./clientes_atrasados";
import Compromissos from "./compromissos";
import ModalIniciarConsulta from "@/components/consultas/modal_iniciar_consulta";
import DialogConfirmacaoAtraso from "@/components/dashboards/dashboard_medico/fila_atual/dialog_confirmacao_atraso.vue";
import DashboardService from "@/services/dashboard_service";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [VuexSnackbarMixin],
  mounted() {
    this.data_consultas = this.getDateToday();
    this.atualizarCompromissos();
  },
  components: {
    FilaAtual,
    FilaTabela,
    ClientesAtrasados,
    Compromissos,
    ModalIniciarConsulta,
    DialogConfirmacaoAtraso,
  },

  data: () => ({
    modalConsulta: false,
    modalAtrasoPaciente: false,
    paciente: {},
    info: {},
    dashboard: "nova",
    compromissos: [],
    loading: false,
    indexCompromissoSelecionado: null,
    idAgendamentoSelecionado: null,
    data_consultas: null,
    consultaId: 0,
    statusComp: 0,
  }),
  watch: {
    statusComp() {
      this.atualizarCompromissos();
    },
    // data_consultas() {
    //   this.atualizarCompromissos();
    // },
  },
  methods: {
    atualizarCompromissos() {
      this.loading = true;
      setTimeout(() => {
        this.compromissos = [];
        const dashboardService = new DashboardService();
        dashboardService.getDashboarMedico(
          {
            onSucess: (status) => (body) => {
              if (status === 200) {
                for (let index = 0; index < body.data.length; index++) {
                  const element = body.data[index];
                  switch (this.statusComp) {
                    case 1:
                      if (element.id_consulta) {
                        this.compromissos.push({
                          idAgendamento: element.id_agendamento,
                          idConsulta: element.id_consulta,
                          emAtraso: !!element.atrasado,
                          data: element.data,
                          horarioInicio: element.hora_inicio,
                          horarioFinal: element.hora_final,
                          nomePaciente: element.nome,
                          idPaciente: element.paciente_id,
                          idCliente: element.id_cliente,
                          procedimento: element.procedimento,
                          tipoProcedimento: element.tipo_procedimento,
                          idProcedimento: +element.id_procedimento,
                          idTipoProcedimento: element.id_tipo_procedimento,
                          idPet: element.id_pet,
                          idRequisicao: element.id_requisicao,
                          nomePet: element.nome_pet,
                          id_requisicao: element.id_requisicao,
                        });
                      }
                      break;
                    case 2:
                      if (element.id_consulta == null) {
                        this.compromissos.push({
                          idAgendamento: element.id_agendamento,
                          idConsulta: element.id_consulta,
                          emAtraso: !!element.atrasado,
                          data: element.data,
                          horarioInicio: element.hora_inicio,
                          horarioFinal: element.hora_final,
                          nomePaciente: element.nome,
                          idPaciente: element.paciente_id,
                          idCliente: element.id_cliente,
                          procedimento: element.procedimento,
                          tipoProcedimento: element.tipo_procedimento,
                          idProcedimento: +element.id_procedimento,
                          idTipoProcedimento: element.id_tipo_procedimento,
                          idPet: element.id_pet,
                          idRequisicao: element.id_requisicao,
                          nomePet: element.nome_pet,
                          id_requisicao: element.id_requisicao,
                        });
                      }
                      break;
                    default:
                      this.compromissos.push({
                        idAgendamento: element.id_agendamento,
                        idConsulta: element.id_consulta,
                        emAtraso: !!element.atrasado,
                        data: element.data,
                        horarioInicio: element.hora_inicio,
                        horarioFinal: element.hora_final,
                        nomePaciente: element.nome,
                        idPaciente: element.paciente_id,
                        idCliente: element.id_cliente,
                        procedimento: element.procedimento,
                        tipoProcedimento: element.tipo_procedimento,
                        idProcedimento: +element.id_procedimento,
                        idTipoProcedimento: element.id_tipo_procedimento,
                        idPet: element.id_pet,
                        idRequisicao: element.id_requisicao,
                        nomePet: element.nome_pet,
                        id_requisicao: element.id_requisicao,
                      });
                      break;
                  }
                }
                // this.compromissos = body.data.map((agenda) => ({
                //   idAgendamento: agenda.id_agendamento,
                //   idConsulta: agenda.id_consulta,
                //   emAtraso: !!agenda.atrasado,
                //   data: agenda.data,
                //   horarioInicio: agenda.hora_inicio,
                //   horarioFinal: agenda.hora_final,
                //   nomePaciente: agenda.nome,
                //   idPaciente: agenda.paciente_id,
                //   idCliente: agenda.id_cliente,
                //   procedimento: agenda.procedimento,
                //   tipoProcedimento: agenda.tipo_procedimento,
                //   idProcedimento: +agenda.id_procedimento,
                //   idTipoProcedimento: agenda.id_tipo_procedimento,
                //   idPet: agenda.id_pet,
                //   idRequisicao: agenda.id_requisicao,
                //   nomePet: agenda.nome_pet,
                //   id_requisicao: agenda.id_requisicao,
                // }));
              }
            },
            onError: (error) => {
              console.error(error);
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: "error",
              });
            },
            onEnd: () => {
              this.loading = false;
            },
          },

          ~~JSON.parse(sessionStorage.vuex).usuario.dados.medicoId,
          this.data_consultas
        );
      }, 1000);
    },
    iniciarConsulta(e) {
      console.log(e);
      this.info = e;
      this.modalConsulta = true;
    },
    cancelarConsulta() {
      this.modalConsulta = false;
      this.info = {};
      this.compromissos = [];
      this.atualizarCompromissos();
    },
    finalizarConsulta(e) {
      console.log({ e });
      this.modalConsulta = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: e.msg,
        color: e.cor,
      });
      this.consultaId = e.consultaId;
      // this.$router.replace({ name: "prontuarios", params: { search: e.nome } });
      this.info = {};
      this.compromissos = [];
    },
    ativarModalAtrasoPaciente(event) {
      this.idAgendamentoSelecionado = event.idAgendamentoSelecionado;
      this.modalAtrasoPaciente = true;
    },
    moverPacienteParaListaDeAtrasados() {
      for (let i = 0, len = this.compromissos.length; i < len; i++) {
        let compromisso = this.compromissos[i];
        if (compromisso.idAgendamento === this.idAgendamentoSelecionado) {
          compromisso.emAtraso = true;
          this.idAgendamentoSelecionado = null;
          break;
        }
      }
      this.modalAtrasoPaciente = false;
    },
    removerPacienteDaListaDeAtrasados(event) {
      for (let i = 0, len = this.compromissos.length; i < len; i++) {
        let compromisso = this.compromissos[i];
        if (compromisso.idAgendamento === event.idAgendamento) {
          compromisso.emAtraso = false;
          break;
        }
      }
    },
    getDateToday() {
      const dateNow = new Date();
      return `${dateNow.getUTCFullYear()}-${(dateNow.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dateNow
        .getDate()
        .toString()
        .padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
.titulo-cards-fila span {
  margin-right: 1rem;
  font-size: 1rem;
}

.titulo-cards-fila hr {
  display: block;
  width: 100%;
  color: #fff;
  margin: 0;
}
.btn-style-green {
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}

/* dashboard nova */
</style>
