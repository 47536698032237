<template>
  <v-card>
    <v-toolbar class="bg-verde" dark>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Atender </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-3 ">
      <div class="d-flex align-items-center justify-content-between my-2">
        <h3>Requisições em aberto</h3>
        <v-btn
          class="bg-verde"
          :dark="requisicoesSelected.length > 0 && !loadingAtender"
          rounded
          :disabled="!requisicoesSelected.length || loadingAtender"
          @click="carregarFormulariosAtendimento"
          >Atender</v-btn
        >
      </div>
      <v-data-table
        v-on:update:sort-by="options.ordenacao = true"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
        :headers="headersRequisicoes"
        :items="requisicoes"
        class="elevation-1"
        :loading="loadingRequisicoes"
        :loading-text="'Carregando...'"
        item-key="requisicao_procedimento_id"
        show-select
        v-model="requisicoesSelected"
      >
        <template v-slot:no-data>
          <p class="text-center">Nenhum registro encontrado</p>
        </template>

        <!-- <template v-slot:item.actions="{ item }">
          <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="atender(item)"
                v-bind="attrs"
                v-on="on"
                small
                class="my-1 bg-verde"
                dark
              >
                ATENDER
              </v-btn>
            </template>
            <v-container class="bg-white" v-if="showSelectFormulario">
              <h4>selecionar tipo de formulário</h4>
              <v-select
                v-model="formularioSelected"
                @change="
                  showModalAtender(formularioSelected.id, item.requisicaoId)
                "
                :items="formularios"
                item-text="descricao"
                return-object
                dense
              ></v-select>
            </v-container>
          </v-menu>
        </template> -->
      </v-data-table>
      <v-dialog
        persistent
        fullscreen
        v-model="showAnamneseModal"
        max-width="500px"
      >
        <modal-anamnese-exame
          @close="showAnamneseModal = false"
          @carregarRequisicoes="carregarRequisicoes"
          @finalizarConsulta="finalizarConsulta()"
          :formularioId="formularioId"
          :requisicoes="requisicoesSelected"
          :paciente="{ nome: petNome }"
          v-if="showAnamneseModal"
        />
      </v-dialog>
      <v-dialog
        v-model="showAvisoMarcarExecutado"
        v-if="showAvisoMarcarExecutado"
        max-width="500px"
        persistent
      >
        <v-card class="d-flex flex-column align-items-center p-2">
          <v-card-text class="mt-2">
            <h4>
              Nenhum formulário foi atrelado a este procedimento. Deseja marcar
              a requisição como executada?
            </h4>
          </v-card-text>
          <v-card-actions class="w-50 justify-content-between">
            <v-btn
              text
              rounded
              :disabled="loadingAtender"
              @click="showAvisoMarcarExecutado = false"
              >Não</v-btn
            >
            <v-btn
              class="bg-verde"
              rounded
              :dark="!loadingAtender"
              :disabled="loadingAtender"
              @click="confirmacaoMarcarExecutado(requisicao)"
              >Sim</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFormularioList"
        v-if="dialogFormularioList"
        persistent
        max-width="480px"
      >
        <v-card>
          <v-card-title>
            <h3>
              Selecione um formulário para realizar o atendimento:
            </h3>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              outlined
              :items="formularios"
              item-text="descricao"
              placeholder="Selecione um formulário"
              return-object
              v-model="formularioSelected"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions
            class="pb-4 mt-n2 d-flex justify-content-around w-75 m-auto"
          >
            <v-btn text rounded @click="dialogFormularioList = false">
              Cancelar
            </v-btn>
            <v-btn
              class="bg-verde"
              rounded
              :dark="!loadingAtender"
              :disabled="loadingAtender"
              @click="showModalAtender(formularioSelected.id)"
            >
              Atender
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import RequisicaoExameService from "@/services/requisicao_exame_service.js";
import FormularioService from "@/services/formulario_service.js";
import ModalAnamneseExame from "../requisitar_exames/modal_anamnese_exame.vue";

export default {
  mixins: [Mixin],
  components: { ModalAnamneseExame },
  data: () => ({
    loadingRequisicoes: false,
    loadingAtender: false,
    dialogAnmanese: false,
    dialogFormularioList: false,
    headersRequisicoes: [
      {
        text: "Procedimento",
        align: "start",
        sortable: true,
        value: "procedimentoDescricao",
      },
      {
        text: "Convênio",
        align: "start",
        sortable: true,
        value: "convenioDescricao",
      },
      {
        text: "Tipo Convênio",
        align: "start",
        sortable: true,
        value: "tipoConvenio",
      },
      // { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    requisicoes: [],
    requisicaoExameService: RequisicaoExameService.build(),
    formularioService: FormularioService.build(),
    loadingRequisicaoId: 0,
    showAnamneseModal: false,
    requisicao: {},
    formularios: [],
    formularioSelected: {},
    formularioId: {},
    requisicaoId: {},
    showSelectFormulario: false,
    showAvisoMarcarExecutado: false,
    requisicoesSelected: [],
  }),
  props: {
    petCodigo: {
      type: Number,
      required: true,
    },
    unidadeId: {
      type: Number,
      required: true,
    },
    petNome: {
      required: false,
    },
  },
  computed: {},
  async mounted() {
    await this.carregarRequisicoes();
  },
  methods: {
    async carregarRequisicoes() {
      this.loadingRequisicoes = true;
      await this.setRequisicoes();
    },
    async setRequisicoes() {
      await this.requisicaoExameService
        .getRequisicoesAbertas(this.petCodigo, this.unidadeId)
        .then(async (response) => {
          const { data } = await response.json();
          if (!data.length) {
            this.requisicoes = [];
            return;
          }
          this.requisicoes = data.map((item) => {
            return {
              ...item,
              formularioId: parseInt(item.formulario),
              filledAnamnese: parseInt(item.status_anamnese),
              requisicaoId: item.requisicao_procedimento_id,
              procedimentoDescricao: item.procedimento_descricao,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
              showModal: false,
            };
          });
        })
        .catch((e) => {
          console.error({ e });
          this.requisicoes = [];
        });

      await this.requisicaoExameService
        .getRequisicoesMedicacoesAbertas(this.petCodigo, this.unidadeId)
        .then(async (response) => {
          const { data } = await response.json();
          data.map((item) => {
            this.requisicoes.push({
              ...item,
              formularioId: parseInt(item.formulario),
              filledAnamnese: parseInt(item.status_anamnese),
              requisicaoId: item.requisicao_procedimento_id,
              procedimentoDescricao: item.procedimento_descricao,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
              showModal: false,
            });
          });
        })
        .catch((e) => {
          console.error({ e });
        });

      await this.requisicaoExameService
        .getRequisicoesInternacoesAbertas(this.petCodigo, this.unidadeId)
        .then(async (resp) => {
          const { data } = await resp.json();
          console.log(data);
          data.map((item) => {
            this.requisicoes.push({
              ...item,
              formularioId: parseInt(item.formulario),
              filledAnamnese: parseInt(item.status_anamnese),
              requisicaoId: item.requisicao_procedimento_id,
              procedimentoDescricao: item.procedimento_descricao,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
              showModal: false,
            });
          });
        })
        .catch((e) => {
          console.error({ e });
        });

      this.loadingRequisicoes = false;
    },
    async marcarExecutado(requisicao) {
      this.loadingRequisicaoId = requisicao.requisicaoId;
      await RequisicaoExameService.build().marcarExecutado(
        ~~requisicao.requisicaoId
      );
      await this.setRequisicoes();
      this.loadingRequisicaoId = 0;
    },
    async finalizarConsulta() {
      this.loadingRequisicaoId = this.requisicao.requisicaoId;
      // await RequisicaoExameService.build().marcarExecutado(
      //   ~~this.requisicao.requisicaoId
      // );
      this.loadingRequisicaoId = 0;
    },
    atender(item) {
      this.requisicao = item;
      console.log(this.requisicao);
      if (item.tipo_procedimento_id == 6) {
        this.carregarFormularios(item);
        return;
      }

      //   this.showModalAtender(item.formularioId, item.requisicaoId);
    },
    async confirmacaoMarcarExecutado(requisicao) {
      this.loadingAtender = true;
      await this.marcarExecutado(requisicao);
      this.showAvisoMarcarExecutado = false;
      this.loadingAtender = false;
    },
    // async carregarFormularios(item) {
    //   this.showSelectFormulario = false;
    //   this.loadingRequisicoes = true;
    //   this.formularios = [];
    //   await this.formularioService.getFormularioByProcedimentoId(
    //     {
    //       onSucess: (status) => (response) => {
    //         if (status === 200) {
    //           this.formularios = response.data
    //             ? response.data.map((formulario) => {
    //                 return {
    //                   id: parseInt(formulario.id_formulario),
    //                   descricao: formulario.descricao,
    //                 };
    //               })
    //             : [];

    //           if (this.formularios < 1) {
    //             this.showAvisoMarcarExecutado = true;
    //           } else if (this.formularios.length > 1) {
    //             this.showSelectFormulario = true;
    //           } else {
    //             this.showModalAtender(
    //               this.formularios[0].id,
    //               item.requisicaoId
    //             );
    //           }
    //         }
    //       },
    //       onError: (error) => {
    //         // this.$emit("close", { error });
    //         console.log(error);
    //       },
    //       onEnd: () => {
    //         this.loadingRequisicoes = false;
    //       },
    //     },
    //     item.procedimento_ide
    //   );
    // },
    showModalAtender(formularioId) {
      this.formularioId = formularioId;
      this.showAnamneseModal = true;
      this.dialogFormularioList = false;
      this.limparFormulario();
    },
    limparFormulario() {
      this.formularios = [];
    },
    async carregarFormulariosAtendimento() {
      this.loadingAtender = true;
      this.formularioService
        .getFormulariosAtendimentoAssitente()
        .then(async (resp) => {
          this.loadingAtender = false;
          if (resp.status == 200) {
            const forms = await resp.json();
            this.formularios = forms.data;
            this.dialogFormularioList = true;
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Nenhum formulário encontrado",
              color: "error",
            });
          }
        });
    },
  },
};
</script>
